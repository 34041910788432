import React, { useState, useEffect, Fragment } from "react";
import { Input, Tabs, Col, Row, Button, message, Form, Spin, Modal } from "antd";
import "./Login.less";
import { UserOutlined, LockOutlined, RedoOutlined, QrcodeOutlined } from "@ant-design/icons";
import Axios from "axios";
import { url } from "../../config/global";

const { TabPane } = Tabs;

function Login(props) {
  const [tab, setTab] = useState("1");
  const [disabledForm, setdisabledForm] = useState(false);
  const [readonlyForm, setreadonlyForm] = useState(false);
  const [login, setLogin] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [qrFocus, setqrFocus] = useState(false);
  const [loadingQR, setloadingQR] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (name, value) => {
    setError(null);
    setLogin({
      ...login,
      [name]: value,
    });
    if (name == 'qr_code') {
      // handleLoginQr(value)
    }
  };

  const handleTab = (key) => {
    setTab(key);
  };

  useEffect(() => {

    if (tab == "3") {
      setqrFocus(true);
    }
  });

  const handleLoginQr = async (value) => {

    setLoading(true);
    setloadingQR(true);
    setreadonlyForm(true);

    await Axios.post(`${url}/qr-login`, {
      qr_code: value,
    })
      .then((res) => {
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("username", res.data.data.username);
        sessionStorage.setItem("fullname", res.data.data.full_name);
        sessionStorage.setItem("id_user", res.data.data._id);
        sessionStorage.setItem("shift_code", res.data.data.shift_code);
        sessionStorage.setItem(
          "avatar",
          res.data.data.photo ? res.data.data.photo_url : ""
        );
        sessionStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        setLogin({
          ...login,
          username: "",
          password: "",
        });
        sessionStorage.setItem("redirect", res.data.redirect);

        message.success(res.data.message);
        setLoading(false);
        setdisabledForm(false);
        setloadingQR(false);
        props.history.push(res.data.redirect);
      })
      .catch((err) => {
        setLogin({
          ...login,
          qr_code: null,
          // password: "",
        });
        setLoading(false);
        setreadonlyForm(false)
        setloadingQR(false);
        setqrFocus(true)
        // setTimeout(a, 2000)
        if (err.response === undefined) {
          Modal.error({
            title: "Failed!",
            content: "Tidak dapat terhubung ke internet, mohon cek koneksi jaringan internet anda",
          });
          // message.error("Can't connect to server, please try again later.");
        } else {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
        }

      });


  }
  const onEnterQr = (e) => {
    if (e.key === 'Enter') {
      handleLoginQr(e.target.value)
    }
  }
  const handleLogin = async () => {

    if ((login.password.length <= 0 && login.username.length <= 0) || ((login.password == "" || login.password === null) && (login.username == "" || login.username === null))) {
      Modal.error({
        title: "Failed!",
        content: "Username and password is required",
      });
      setLogin({
        ...login,
        username: "",
        password: "",
      });
      return false;

    }
    setLoading(true);
    setdisabledForm(true);
    await Axios.post(`${url}/login`, {
      username: login.username,
      password: login.password,
    })
      .then((res) => {
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("username", res.data.data.username);
        sessionStorage.setItem("fullname", res.data.data.full_name);
        sessionStorage.setItem("id_user", res.data.data._id);
        sessionStorage.setItem("shift_code", res.data.data.shift_code);
        sessionStorage.setItem(
          "avatar",
          res.data.data.photo ? res.data.data.photo_url : ""
        );
        sessionStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        setLogin({
          ...login,
          username: "",
          password: "",
        });
        sessionStorage.setItem("redirect", res.data.redirect);

        message.success(res.data.message);
        setLoading(false);
        setdisabledForm(false);
        props.history.push(res.data.redirect);
      })
      .catch((err) => {
        setLogin({
          ...login,
          // username: null,
          password: "",
        });
        setLoading(false);
        setdisabledForm(false);
        if (err.response === undefined) {
          Modal.error({
            title: "Failed!",
            content: "Can't connect to server, please try again later.",
          });
          // message.error("Can't connect to server, please try again later.");
        } else {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
        }

      });
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <div className="main">
            <div>
              <div className="img-box">
              	<div style={{ maxWidth: "100%", backgroundColor: "red" }}>
  					<h1 style={{ textAlign: "center" }}>TRIAL</h1>
				</div>
                <img
                  src={require("../../assets/img/pt-sankei-dharma-indonesia.jpg")}
                  className="img-logo"
                />
              </div>

              <Tabs activeKey={tab} onChange={handleTab}>
                <TabPane tab="Sign In" key="1">
                  <Form onFinish={handleLogin}>
                    <Form.Item
                      validateStatus={error && error.username ? "error" : false}
                      help={error && error.username ? error.username[0] : false}
                    >
                      <Input
                        className="login-input"
                        prefix={<UserOutlined />}
                        disabled={disabledForm}
                        placeholder="Username"
                        onChange={(e) => handleChange("username", e.target.value)}
                        value={login.username}
                      />
                    </Form.Item>
                    <Form.Item
                      validateStatus={error && error.password ? "error" : false}
                      help={error && error.password ? error.password[0] : false}
                    >
                      <Input
                        className="login-input"
                        prefix={<LockOutlined />}
                        disabled={disabledForm}
                        type="password"
                        placeholder="Password"
                        onChange={(e) => handleChange("password", e.target.value)}
                        value={login.password}
                      />
                    </Form.Item>
                    {/* <div>
                <Button
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                  className="button-login"
                >
                  Login
                </Button>
              </div> */}
                    <div style={{ display: "flex" }}>
                      <div style={{ margin: "auto" }}>
                        <Button
                          htmlType="submit"
                          loading={loading}
                          type="primary"
                          className="button-login"
                        >
                          Login
                        </Button>
                      </div>
                    </div>
                  </Form>
                </TabPane>

                <TabPane tab="Sign in with QR Code" key="3">
                  {tab === "3" ? (

                    <Form.Item
                      validateStatus={error && error.username ? "error" : false}
                      help={error && error.username ? error.username[0] : false}
                    >
                      <Input
                        autoFocus
                        className="login-input"
                        prefix={<QrcodeOutlined />}
                        readOnly={readonlyForm}
                        // type="password"
                        autoComplete={false}
                        onKeyDown={(e) => onEnterQr(e)}
                        placeholder="Please Scan QR Code to sign in"
                        onChange={(e) => handleChange("qr_code", e.target.value)}
                        value={login.qr_code}
                      />
                    </Form.Item>

                  ) : (
                    <div className="barcode-fail">
                      <p>Login Failed</p>
                      <p>Retry</p>
                      <Button type="link" size="large" >
                        <RedoOutlined />
                      </Button>
                    </div>
                  )}

                </TabPane>

              </Tabs>
              {loadingQR == true ? (<div ><Spin /> Checking QR Code...</div>) : (<span></span>)}

            </div>
          </div>
        </Col>
      </Row>
    </Fragment>

  );
}

export default Login;
